<template >
  <div class="containter">
    <div class="headerFixed">
      <div class="daohangLine">
        <img src="./imgs/mysj_logo.png" class="mysj_logo_img" />
        <div class="openOrCloseArea">
          <img
            v-if="!openMenu"
            @click="changeOpenMenu(true)"
            src="./imgs/openMenu.png"
            style="width:100%;height:100%;"
          />
          <img
            v-if="openMenu"
            @click="changeOpenMenu(false)"
            src="./imgs/cha.jpg"
            style="width:100%;height:100%;"
          />
        </div>
      </div>
    </div>
    <van-popup
      v-model="openMenu"
      position="top"
      :style="{ height: '10rem',backgroundColor:'#1E1E1E' }"
    >
      <div
        v-for="item in openMenuList"
        :key="item.id"
        @click="locationToPlace(item.id)"
        class="openMenuTabItem"
      >
        <div class="openMenuTabItemTitle">
          <div class="openMenuTabItemTitleLine">{{item.title}}</div>
          <div v-if="item.ohter" class="openMenuTabItemOtherLine">{{item.ohter}}</div>
        </div>
        <img src="./imgs/arrow_right.png" class="arrow_right" />
      </div>
      <div class="openMenuTabTelLine">售前咨询热线：400-663-7058</div>
      <div class="height110"></div>
    </van-popup>
    <div class="height50"></div>
    <div class="infoListArea">
      <div class="height16"></div>
      <div
        v-for="item in informationList"
        :key="item.id"
        @click="gotoInfoDetail(item.id)"
        class="infoListAreaItem"
      >
        <img :src="item.img" class="infoListAreaItemImg" />
        <div class="infoListAreaItemTitle">{{item.title}}</div>
        <div class="infoListAreaItemDate">{{item.date}}</div>
      </div>
    </div>
    <div class="lastArea">
      <div class="fourServerArea">
        <div class="fourServerItem margin24Left">
          <img src="./imgs/fourServe_1.png" class="fourServeImg" />
          <div class="fourServeFont">开通即用</div>
        </div>
        <div class="fourServerItem">
          <img src="./imgs/fourServe_2.png" class="fourServeImg" />
          <div class="fourServeFont">定制化服务</div>
        </div>
        <div class="fourServerItem">
          <img src="./imgs/fourServe_3.png" class="fourServeImg" />
          <div class="fourServeFont">1V1大客户服务</div>
        </div>
        <div class="fourServerItem margin24Right">
          <img src="./imgs/fourServe_4.png" class="fourServeImg" />
          <div class="fourServeFont">7*24小时服务</div>
        </div>
      </div>
      <div class="lastBottomBg">
        <div v-for="item in lastBottomBgList" :key="item" class="lastBottomBgItem">{{item}}</div>
      </div>
      <bottom-list
        :bottomList="bottomList"
        :bottomListSelect="bottomListSelect"
        @changeBottomListSelect="changeBottomListSelect"
      ></bottom-list>
      <div class="bottomMsgArea">
        <div class="height43"></div>
        <div class="bottomMsgTel">售前咨询热线：400-663-7058</div>
        <div class="greyLine"></div>
        <div class="bottomMsgCompany">
          Copyright © 杭州美云数据有限公司 All rights reserved
          浙ICP备20014233号
        </div>
      </div>
    </div>
    <banner-bottom></banner-bottom>
  </div>
</template>

<script>
import { Notify } from 'vant';
import BottomList from './bottomList';
import BannerBottom from './BannerBottom';
export default {
  name: 'InfoList',
  components: {
    BottomList,
    BannerBottom
  },
  data() {
    return {
      openMenu: false,
      bannersList: [
        {
          id: 1,
          linkUrl: require('./imgs/banner1@2x.png')
        },
        {
          id: 2,
          linkUrl: require('./imgs/banner2@2x.png')
        },
        {
          id: 3,
          linkUrl: require('./imgs/banner3@2x.png')
        }
      ],
      solveImgList: [
        { img: require('./imgs/solveIcon_1.png'), name: '数据接入' },
        { img: require('./imgs/solveIcon_2.png'), name: '清洗融合' },
        { img: require('./imgs/solveIcon_3.png'), name: '数据可视化' },
        { img: require('./imgs/solveIcon_4.png'), name: '态势分析' },
        { img: require('./imgs/solveIcon_5.png'), name: '决策依据' }
      ],
      selectSolveImg: 1,
      solvePlanSelectList: [
        {
          id: 1,
          img: require('./imgs/solvePlan_1.png'),
          name: '数据驾驶舱'
        },
        {
          id: 2,
          img: require('./imgs/solvePlan_2.png'),
          name: '智慧党建'
        },
        {
          id: 3,
          img: require('./imgs/solvePlan_3.png'),
          name: '垃圾分类'
        },
        {
          id: 4,
          img: require('./imgs/solvePlan_4.png'),
          name: '智慧文旅'
        },
        {
          id: 5,
          img: require('./imgs/solvePlan_5.png'),
          name: '时空印象'
        }
      ],
      solvePlanSelect: 0,
      buildSettingSelectList: [
        {
          id: 1,
          img: require('./imgs/buildSetting_1.png'),
          name: '国家政策引领',
          content:
            '党中央、国务院高度重视大数据发展，将大数据上升为我国国家战略之一。\n一、中共中央国务院关于深入推进城市执法体制改革改进城市管理工作的指导意见（中发〔2015〕37号）指出： 建立用数据说话、用数据决策、用数据管理、用数据创新的新机制。\n二、十九大之后不久，中共中央政治局就实施国家大数据战略进行第二次集体学习。 习近平总书记指出，善于获取数据、分析数据、运用数据，是领导干部做好工作的基本功。'
        },
        {
          id: 2,
          img: require('./imgs/buildSetting_2.png'),
          name: '地方政策不断出台',
          content:
            '中共浙江省委办公厅、浙江省人民政府办公厅印发《浙江省小城镇环境综合整治行动实施方案》，美丽城镇的建设主要目标是：到2020年，有100个左右的小城镇率先达到美丽城镇样板要求；到2020年，有300个左右小城镇达到美丽城镇样板要求，城乡融合发展体制初步建立；到2035年，城乡融合发展体制机制更加完善，全省小城镇高质量全面建成美丽城镇，同步高水平基本现代化。2019年9月，浙江发布《浙江省美丽城镇建设指南》，其中指出打造美丽城镇的重要意义：建设新时代美丽城镇，是推进“八八战略”再深化、改革开放再出发作出的重大部署，也是践行初心使命、实现人民对美好生活向往的实际行动。'
        },
        {
          id: 3,
          img: require('./imgs/buildSetting_3.png'),
          name: '城镇发展必然趋势',
          content:
            '随着美丽城镇管理工作的不断推进，数字化建设平台积累的数据不断增多，相关业务口系统成熟度不断提高，运用大数据技术实现城镇管理的决策科学化、治理精准化、服务高效化，是城镇管理发展的必然趋势！'
        }
      ],
      buildSettingSelect: 0,
      serverSupportList: [
        {
          id: 1,
          linkUrl: require('./imgs/serverSupport_1.png')
        },
        {
          id: 2,
          linkUrl: require('./imgs/serverSupport_2.png')
        },
        {
          id: 3,
          linkUrl: require('./imgs/serverSupport_3.png')
        },
        {
          id: 4,
          linkUrl: require('./imgs/serverSupport_4.png')
        }
      ],
      serverSupportShowList: [
        {
          id: 1,
          img: require('./imgs/serSupportIcon_1.png'),
          name: '多端协同'
        },
        {
          id: 2,
          img: require('./imgs/serSupportIcon_2.png'),
          name: '轻量化交付'
        },
        {
          id: 3,
          img: require('./imgs/serSupportIcon_3.png'),
          name: '流畅操作体验'
        }
      ],
      mysjData:
        '      杭州美云数据科技有限公司是一家大数据，AI，5G，人工智能的政务综合解决方案的提供商。主要从事新型智慧城市顶层设计，大数据集成平台，通过数据智能技术，大数据深度学习与开放平台和可视化呈现技术，服务于政府，公安，综治，城管，医疗，旅游，园区等行业。我们坚持将技术领先与业务使能相结合，以技术让生活更美好为使命，以数据技术和商业平台实现中国梦为愿景，让智能、便捷的数字生活惠享世界。\n \n      美云团队拥有近20年的数据处理能力和ICT 领域经验，带来领先的数字化转型技术和数据智能服务。目前，美云数据业务范围主要包含数字城镇，智慧园区的PASS平台和综合政务SAAS标准软件和增值定制解决方案，其中，形成了在通信软件、运营服务、云计算、大数据、人工智能、互联网架构等领域的核心竞争力。',
      lastBottomBgList: [
        '全方位产品解读',
        '专业技术咨询',
        '成功案例分享',
        '成熟解决方案'
      ],
      bottomListSelect: 0,
      bottomList: [
        {
          id: 1,
          name: '基础应用',
          list: ['组织架构', '数据申报', '管理工作台']
        },
        {
          id: 2,
          name: '环境美应用',
          list: ['智能排水', '垃圾分类', '智慧公厕', '智慧停车', '智慧消防']
        },
        {
          id: 3,
          name: '生活美应用',
          list: ['智慧路灯', '智慧医疗', '智慧养老', '智慧校园', '邻里互助']
        },
        {
          id: 4,
          name: '产业美应用',
          list: ['智慧物流', '智慧工业', '智慧农业', '产业集群', '一企一档']
        },
        {
          id: 5,
          name: '人文美应用',
          list: ['智慧景区', '智慧文旅', '智慧党建', '小区改造', '绿化管理']
        },
        {
          id: 6,
          name: '治理美应用',
          list: ['智慧治理', '智慧城管', '智慧小区', '智慧安防', '智慧物业']
        }
      ],
      openMenuList: [
        {
          id: 1,
          title: '解决方案',
          ohter: '让每个决策都有数据支撑'
        },
        {
          id: 2,
          title: '建设背景',
          ohter: '全新智慧城镇管理服务平台'
        },
        {
          id: 3,
          title: '服务支持',
          ohter: '提升智能化决策能力'
        },
        {
          id: 4,
          title: '美云数据'
        },
        {
          id: 5,
          title: '合作数据'
        }
      ],
      // 热点资讯的四张图
      informationList: [
        {
          id: 1,
          img: require('./imgs/infoPageList1.png'),
          title: '湖州举行美丽城镇建设培训会 美云受邀分享智慧管家行动方',
          date: '2020-06-04'
        },
        {
          id: 2,
          img: require('./imgs/infoPageList2.png'),
          title: '嘉兴举行美丽城镇智慧管家建设培训会 美云为南湖建设展示',
          date: '2020-05-30'
        },
        {
          id: 3,
          img: require('./imgs/infoPageList3.png'),
          title: '解密众多乡镇点赞的美丽城镇智慧管家',
          date: '2020-05-30'
        },
        {
          id: 4,
          img: require('./imgs/infoPageList4.png'),
          title: '美云数据宣传片首公开：智慧美丽城镇 拥抱无限可能',
          date: '2020-05-26'
        }
      ]
    };
  },
  watch: {},
  mounted() {
    this.$nextTick(function() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });
  },
  created() {},
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    // fgsdgsd
    changeOpenMenu(value) {
      console.log(value);
      this.openMenu = value;
    },
    // 定位到具体位置
    locationToPlace(num) {
      this.solvePlanSelect = 0;
      this.buildSettingSelect = 0;
      this.bottomListSelect = 0;
      this.$router.push({ name: 'index', query: { id: num } });
      this.openMenu = false;
    },
    // 打开banner图的链接
    openBannerPage(url) {
      window.open(url);
    },
    // 解决方案的图的点击轮换
    solvePlanTurnRightOrLeft(direction) {
      if (direction === 'left') {
        if (this.selectSolveImg === 1) {
          this.selectSolveImg = 5;
        } else {
          this.selectSolveImg -= 1;
        }
      } else {
        if (this.selectSolveImg === 5) {
          this.selectSolveImg = 1;
        } else {
          this.selectSolveImg += 1;
        }
      }
    },
    changeBottomListSelect(value) {
      this.bottomListSelect = Number(value);
    },
    // 去资讯详情页
    gotoInfoDetail(id) {
      this.$router.push({ name: 'infoDetail', query: { id: id } });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;

/deep/ .van-collapse-item__content {
  padding: 0px;
}
/deep/ .van-cell {
  padding: 12 * $px 0.42667rem;
  color: #333333;
}
/deep/ .van-collapse-item__title--expanded {
  color: #ffa500;
}
/deep/ .van-popup--top {
  top: 50 * $px;
  left: 0;
  width: 100%;
}
.containter {
  width: 100%;
}
.headerFixed {
  width: 100%;
  height: 50 * $px;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5000;
}
.titleLine {
  width: 100%;
  height: 44 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 18 * $px;
}
.daohangLine {
  width: 100%;
  height: 50 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mysj_logo_img {
  width: 107 * $px;
  height: 32.65 * $px;
  margin-left: 24 * $px;
}
.openOrCloseArea {
  width: 24 * $px;
  height: 24 * $px;
  margin-right: 28 * $px;
  display: flex;
}
.height94 {
  width: 100%;
  height: 94 * $px;
}
.height50 {
  width: 100%;
  height: 50 * $px;
}
.openMenuTabItem {
  width: 100%;
  height: 93 * $px;
  border-bottom: #333333 0.6 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bannerArea {
  width: 100%;
  height: 190 * $px;
  overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20 * $px;
  line-height: 190 * $px;
  text-align: center;
  height: 100%;
  background-color: rgb(244, 215, 219);
}
.bannerImg {
  height: 190 * $px;
}
.solvePlanTab {
  width: 100%;
  height: 227 * $px;
}
.solvePlanTabTitle {
  width: 100%;
  height: 28 * $px;
  margin-top: 24 * $px;
  font-size: 20 * $px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solvePlanTabSmallTitle {
  width: 100%;
  height: 17 * $px;
  margin-top: 6 * $px;
  font-size: 12 * $px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solvePlanLunList {
  width: 100%;
  height: 128 * $px;
  margin-top: 16 * $px;
  display: flex;
  justify-content: space-between;
}
.solvePlanLunListLeft {
  width: 36 * $px;
  height: 36 * $px;
  margin-left: 26 * $px;
  margin-top: 32 * $px;
  display: flex;
}
.solvePlanLunListRight {
  width: 36 * $px;
  height: 36 * $px;
  margin-right: 26 * $px;
  margin-top: 32 * $px;
  display: flex;
}
.solvePlanLunCenter {
  width: 100 * $px;
  height: 128 * $px;
}
.solvePlanLunCenterImg {
  width: 100 * $px;
  height: 100 * $px;
}
.solvePlanLunCenterFont {
  width: 100%;
  height: 20 * $px;
  margin-top: 4 * $px;
  font-size: 14 * $px;
  color: #ffa500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.baseLine {
  width: 100%;
  height: 1 * $px;
  background-color: #eeeeee;
}
.solvePlanSelectImgArea {
  width: 100%;
  height: 174 * $px;
  position: relative;
}
.solvePlanSelectImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.solvePlanSelectImgAreaDetail {
  width: 84 * $px;
  height: 32 * $px;
  position: absolute;
  border: #ffffff 1 * $px solid;
  border-radius: 2 * $px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14 * $px;
  top: 94 * $px;
  left: 24 * $px;
}
.divideLine {
  width: 100%;
  height: 10 * $px;
  background-color: #f5f5f5;
}
.height24 {
  width: 100%;
  height: 24 * $px;
}
.buildSettingTitle {
  width: 100%;
  height: 28 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20 * $px;
  color: #000000;
}
.buildSettingSmallTitle {
  width: 100%;
  height: 17 * $px;
  color: #999999;
  font-size: 12 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6 * $px;
}
.height16 {
  width: 100%;
  height: 16 * $px;
}
.buildSettingSelectimg {
  width: 359 * $px;
  height: 240 * $px;
  margin-left: 8 * $px;
}
.buildSettingSelectContent {
  width: 327 * $px;
  font-size: #666666;
  font-size: 12 * $px;
  line-height: 150%;
  white-space: pre-wrap;
  margin: 0 auto;
}
.serverSupportImg {
  width: 100%;
  height: 140 * $px;
  overflow-x: scroll;
}
.serverSupportImgList {
  width: 1268 * $px;
  height: 100%;
}
.serverSupportLunBoImg {
  width: 300 * $px;
  height: 140 * $px;
  margin-left: 17 * $px;
}
.serverSupportShowTab {
  width: 100%;
  height: 136 * $px;
  display: flex;
  justify-content: center;
}
.serverSupportShowTabItem {
  width: 118 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.serverSupportShowTabItemImg {
  width: 48 * $px;
  height: 48 * $px;
  margin-top: 32 * $px;
}
.serverSupportShowTabItemFont {
  height: 20 * $px;
  width: 100%;
  color: #333333;
  font-size: 14 * $px;
  margin-top: 12 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysj-area {
  width: 100%;
  height: 420 * $px;
  background: url(./imgs/mysj_bg.png) no-repeat;
  background-size: 100% 100%;
}
.mysj-area-title {
  width: 100%;
  height: 28 * $px;
  font-size: 20 * $px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysj-p {
  width: 327 * $px;
  margin: 24 * $px auto;
  color: #ffffff;
  font-size: 12 * $px;
  white-space: pre-wrap;
  line-height: 150%;
}
.couperationArea {
  width: 100%;
  height: 338 * $px;
}
.coopearationImg {
  width: 100%;
  height: 260 * $px;
  margin-top: 12 * $px;
}
.lastArea {
  width: 100%;
  background-color: #1e1e1e;
}
.fourServerArea {
  padding-left: 9.5 * $px;
  padding-right: 9.5 * $px;
  height: 130 * $px;
  display: flex;
  justify-content: space-between;
}
.fourServerItem {
  width: 60 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fourServeImg {
  width: 44 * $px;
  height: 44 * $px;
  margin-top: 24 * $px;
}
.fourServeFont {
  width: 100%;
  text-align: center;
  line-height: 150%;
  font-size: 12 * $px;
  color: #ffffff;
  margin-top: 5 * $px;
}
.margin24Left {
  margin-left: 24 * $px;
}
.margin24Right {
  margin-right: 24 * $px;
}
.lastBottomBg {
  width: 100%;
  height: 150 * $px;
  background: url(./imgs/lastBottomBg.png) no-repeat;
  background-size: 100% 100%;
}
.lastBottomBgItem {
  width: 50%;
  height: 22 * $px;
  margin-top: 32 * $px;
  margin-bottom: 10 * $px;
  float: left;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14 * $px;
}
.bottomMsgArea {
  width: 100%;
  height: 222 * $px;
}
.bottomMsgTel {
  width: 343 * $px;
  height: 48 * $px;
  background-color: #ffffff;
  font-size: 18 * $px;
  color: #333333;
  border-radius: 4 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}
.greyLine {
  width: 327 * $px;
  height: 1 * $px;
  background-color: #333333;
  margin: 24 * $px auto 14 * $px;
}
.height43 {
  height: 43 * $px;
  width: 100%;
}
.bottomMsgCompany {
  width: 250 * $px;
  margin: 0 auto;
  line-height: 150%;
  text-align: center;
  font-size: 10 * $px;
  color: #ffffff;
}
.openMenuTabItemTitle {
  width: 250 * $px;
  height: 100%;
  margin-left: 24 * $px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.arrow_right {
  width: 12.5 * $px;
  height: 12.5 * $px;
  margin-right: 27 * $px;
}
.openMenuTabItemTitleLine {
  color: #ffffff;
  font-size: 16 * $px;
}
.openMenuTabItemOtherLine {
  color: rgb(64, 64, 64);
  font-size: 12 * $px;
  margin-top: 7 * $px;
}
.openMenuTabTelLine {
  margin-left: 24 * $px;
  margin-top: 77 * $px;
  color: #ffffff;
  font-size: 14 * $px;
}
.hotInformationTab {
  width: 100%;
  height: 240 * $px;
  background-color: #ffffff;
}
.greyLine10 {
  width: 100%;
  height: 10 * $px;
  background-color: #f5f5f5;
}
.hotInformationTabTitle {
  width: 100%;
  height: 28 * $px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 20 * $px;
  margin-top: 24 * $px;
  margin-bottom: 25 * $px;
}
.hotInformationTabTitleImg {
  width: 22 * $px;
  height: 22 * $px;
  margin-left: 6 * $px;
}
.hotInformationTabTitleFont {
  margin-left: 147 * $px;
}
.hotInformationTabListOut {
  width: 100%;
  margin-left: 16 * $px;
  height: 140 * $px;
  overflow-x: scroll;
}
.hotInformationTabList {
  width: 1272 * $px;
  height: 100%;
}
.hotInformationTabListImg {
  width: 300 * $px;
  height: 100%;
  margin-right: 18 * $px;
}
.infoListArea {
  height: 910 * $px;
  width: 100%;
  background-color: #f5f5f5;
}
.infoListAreaItem {
  width: 343 * $px;
  height: 206 * $px;
  margin: 0px 16 * $px 16 * $px 16 * $px;
  background-color: #ffffff;
  border: #eeeeee 1 * $px solid;
}
.infoListAreaItemImg {
  width: 100%;
  height: 120 * $px;
}
.infoListAreaItemTitle {
  width: 320 * $px;
  height: 40 * $px;
  margin: 8 * $px auto;
  color: #333333;
  font-size: 14 * $px;
  line-height: 140%;
}
.infoListAreaItemDate {
  height: 14 * $px;
  margin-left: 11 * $px;
  color: #999999;
  font-size: 12 * $px;
}
.height110 {
  width: 100%;
  height: 110 * $px;
}
</style>





